// colors
$colors: (
  gray: (
    0: #ffffff,
    100: #fcfcfd,
    200: #ebebef,
    300: #8e8ea9,
    500: #343452
  ),
  blue: (
    100: #e1eefa,
    200: #00C8ff,
    300: #0082ff,
    400: #0073e1,
    500: #4AA5FD
  ),
  red: (
    100: #f35c5c,
    200: #f04242,
  ),
  green:(
    100: #c3ff87
  )
);

// shadows
$shadow-07: 0 0px 15px 0 rgb(165, 165, 165, .07);
$shadow-08: 0 4px 14px 0 rgba(165, 165, 165, .1);

// gradients
$gradient: linear-gradient(270deg, #04b0ff 0%, #009bff 100%);

// calendar
$daySize: 32px;

// wheel
$entrySize: 48px;
