.page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 2rem);
  margin: 1rem;
  background-color: var(--gray-0);
}

.spacer {
  flex: 1;
}


.info {
  display: inline-block;
  color: var(--red-200);
}


.header {
  display: flex;
  align-self: center;
  margin: 2.5rem 0 1rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    background: url(../assets/background.jpg) no-repeat bottom;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
  }
}

.logo {
  display: flex;
  position: relative;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background: white url(../assets/logo.png) no-repeat center;
  background-size: 80%;
}

.dialogContainer {
  padding: 1.5rem;
}

.description {
  padding-bottom: 2rem;
}

.cancel {
  color: var(--gray-500);
  background: transparent;
  margin-top: 1rem;
}

.title {
  font-size: .8125rem;
  margin: 0 auto 3.5rem auto;
  text-transform: uppercase;
}