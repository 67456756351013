@import "../../variables";

.wrapper {
  position: relative;
  z-index: 100;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/background.jpg") center;
  background-size: cover;
}

.bar {
  position: fixed;
  bottom: calc(-100vh + 256px);
  width: 100%;
  height: 100vh;
  background-color: var(--gray-0);
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 1.5rem;
  color: var(--gray-0);
  background: url("../../assets/background.jpg") bottom;
  background-size: cover;
  font-size: .8125rem;
  text-transform: uppercase;
}

.arrow {
  color: var(--blue-300);
  background-color: var(--gray-0);
  border-radius: 50%;
}
