.prompt {
    top: 50% !important;
    overflow-y: hidden;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    height: calc(50vh - 1.5rem);
  }
  
  .title {
    margin-bottom: 1rem;
    font-size: 1rem;
    text-align: left;
    text-transform: uppercase;
    flex: 0.25;
  }
  
  .question {
    text-align: left;  
    flex: 0.5;
    white-space: pre-line;   
  }
    
.title {
  margin-bottom: 1rem;
  font-size: .8125rem;
  text-align: center;
  text-transform: uppercase;
}

.spacer {
  flex: 1;
}

.cancel {
  color: var(--gray-500);
  background: transparent;
}

  