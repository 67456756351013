.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20rem);
  margin-top: calc(10rem + env(safe-area-inset-top));
  background: var(--gray-100);
}

.calendar {
  top: calc(env(safe-area-inset-top) + 4.25rem) !important;
}

.month {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  height: 4.25rem;
  padding: 0 1.25rem;
  background-color: var(--gray-0);
  font-size: 1.25rem;
  font-weight: 500;
  z-index: 100;
}

.sessionCompletedWrapper {
  margin: 2rem;
}

.willBeActivewrap {
  height: 100%;
  display: flex;
  padding: 2rem;
}

.willBeActive {
  color: var(--gray-300);
  margin: auto;
}

.anchor {
  margin-top: .5rem;
  margin-bottom: 2rem;
  color: var(--blue-300);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  align-self: baseline;
  text-align: center;
}
