.page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 2rem);
  margin: 1rem;
  background-color: var(--gray-0);
}

.spacer {
  flex: 1;
}

.forgot {
  font-size: .875rem;
  box-shadow: none;
}

.header {
  display: flex;
  align-self: center;
  margin: 2.5rem 0 1rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    background: url(../assets/background.jpg) no-repeat bottom;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
  }
}

.logo {
  display: flex;
  position: relative;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background: white url(../assets/logo.png) no-repeat center;
  background-size: 80%;
}
.authError {
  display: inline-block;
  height: 1.5rem;
  color: var(--red-200);
  font-size: .875rem;
  overflow: hidden;
}
