.settings {
  padding: env(safe-area-inset-top) 1.25rem 0;
  background-color: var(--gray-0);
}

.header {
  display: flex;
  align-items: center;
  height: 4.25rem;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--gray-200);
}

.back {
  height: .65rem;
  margin: 0 1rem 2px 0;
}

.option {
  display: flex;
  align-items: center;
  color: var(--blue-300);
  border-bottom: 1px solid var(--gray-200);
}

.icon {
  width: 1.5rem;
  margin-right: 1.5rem;
}

.action {
  width: 100%;
  padding: 1.75rem 0;
  color: inherit;
  font-size: .8125rem;
  font-weight: 500;
  text-align: start;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
}

.red {
  color: var(--red-200);
}
