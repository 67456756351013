.header {
  top: 3.5rem;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  height: calc(100vh - 13rem);
  text-align: center;
}

.message {
  margin-bottom: 0.75rem;
  color: var(--gray-300);
  font-size: .8125rem;
  text-align: center;
  text-transform: uppercase;
}

.select {
  display: flex;
  flex-direction: column;
  margin: 1rem 1.25rem 4.5rem;

  &>button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.startButton {
  display: flex;
  margin: 2rem 0;
  justify-content: center !important;
}

.chargerId {
  font-weight: 800;
}

.centerVertically {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  text-align: center;
}

.list svg{
  color: var(--gray-300);
  width: 100%;
}

.button{
  margin-top:1rem;
}