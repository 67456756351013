@import "../../variables";

.header {
  box-shadow: $shadow-08;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  padding: 0 1.25rem;
}

.calendar {
  transform: scale(.75);
}

.anchor {
  display: inline-block;
  margin-top: .5rem;
  color: var(--blue-300);
  font-size: .8125rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  align-self: baseline;
}

.editableArea {
  display: inline-block;
  margin-top: .5rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;  
}

.button {
  margin: 1.5rem 0;
}

