@import '../../variables';

.header {
  display: flex;
  align-items: center;
  height: 4.25rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--gray-200);
}

.back {
  height: .65rem;
  margin: 0 1rem 2px 0;
}

.action {
  margin-left: auto;
  color: var(--blue-300);
  font-size: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.card {
  width: 100%;
  margin-bottom: 1.25rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: $shadow-08, 0 0 0 1px inset var(--gray-200);

  &:disabled {
    pointer-events: none;
  }
}

.inner {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.spacer {
  flex: 1;
}

.invalid {
  opacity: .5;
}
