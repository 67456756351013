.prompt {
  top: 50% !important;
}

.content {
  display: flex;
  flex-direction: column;
  height: calc(50vh - 1.5rem);
}

.title {
  margin-bottom: 1rem;
  font-size: 1rem;
  text-align: left;
  text-transform: uppercase;
  flex: 0.25;
}

.question {
  text-align: left;  
  flex: 0.5;   
}

.keep {
  color: var(--blue-500);
  background: transparent;
}

.cancelRec {
  margin-top: 1rem;
  color: var(--gray-500);
  background: #e2f76e;
}

.cancelReserve {
    color: white;
  background: #F04242;  
}
