.container {
  margin: calc(5.5rem + env(safe-area-inset-top)) 0 1.5rem;
}

.date {
  margin: 2.5rem 1.25rem 1rem;
  color: var(--gray-300);
  font-size: .8125rem;
  text-transform: uppercase;
}

.spinner {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}


.sessionCompletedWrapper {
  margin: 2rem;
}

.willBeActivewrap {
  height: 100%;
  display: flex;
  padding: 2rem;
}

.willBeActive {
  color: var(--gray-300);
  margin: auto;
}

.anchor {
  margin-top: .5rem;
  margin-bottom: 2rem;
  color: var(--blue-300);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  align-self: baseline;
  text-align: center;
}
